import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import Layout from "../../components/layout";
import Arrow from "../../../static/icons/arrow_lg.inline.svg";
import Title from '../../components/title';

export default ({ location, data }) => (
    
    <Layout
      path={location.pathname}>

      <Helmet>
        <title>Docs</title>
      </Helmet>

      <Title
        subtitle="Docs"
        title="Simple guides to get you started on Slate"
        width="max-w-xs md:max-w-md lg:max-w-xl" />

      <div className="lg:container max-w-3xl mx-auto">

        <div className="doc__list space-y-4 lg:space-y-8">

        { data.prismic.allDocs.edges.map(item => (
          <div className="doc__item border-t border-box_dark pt-4 lg:pt-8 md:text-lg font-medium">
            <a href={`/docs/${item.node._meta.uid}`}>
              <span>{item.node.title[0].text}</span>
              <span className="text-accent"><Arrow /></span>
            </a>
          </div>
        ))}

        </div>

      </div>

    </Layout>
)

export const query = graphql`
{
  prismic {
    allDocs(sortBy: order_ASC) {
      edges {
        node {
          title
          text
          _meta {
            uid
            type
          }
        }
      }
    }
  }
}
`